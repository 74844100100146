<template>
  <div>
    <PageHeader fileName="header-6" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h2 v-html="$lang('section1.heading')" />
          </div>
          <div class="col-lg-7 offset-lg-1">
            <p class="h6 mb-4">
              {{ $lang("section1.paragraph1") }}
            </p>

            <a
              class="btn btn-primary btn-block text-center py-2"
              href="https://www.linkedin.com/company/nlcollege-spain/jobs/?viewAsMember=true"
              target="_blank"
            >
              {{ $lang("section1.btn") }}
            </a>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-lg-5">
            <h3 class="text-primary">{{ $lang("section2.title") }}</h3>
          </div>
          <div class="col-lg-7">
            <ul class="disc-style disc-primary">
              <li
                class="h6"
                v-for="(step, si) of $lang('section2.steps')"
                :key="si"
                v-html="step"
              />
            </ul>
          </div>
        </div>

        <div class="row align-items-end mt-5">
          <div class="col-lg-4 order-1 order-lg-0">
            <IconCard
              icon="building"
              theme="primary"
              class="card-button"
              :to="{ name: 'our_campus' }"
            >
              <div>
                <h6>{{ $lang("card.heading") }}</h6>
                <p class="m-0 lh-1">
                  <small> {{ $lang("card.description") }} </small>
                </p>
              </div>
            </IconCard>
          </div>
          <div class="col-lg-7 offset-lg-1">
            <div class="card card-dark p-md-5 p-3 mb-4 mb-lg-0">
              <div class="card-header">
                <h4 class="text-primary">{{ $lang("form.title") }}</h4>
              </div>
              <div class="card-body">
                <form
                  action="https://api.staticforms.xyz/submit"
                  method="post"
                  class="contact-form"
                  :class="{ tried }"
                >
                  <input
                    type="text"
                    :placeholder="`${$lang('contact_form.full_name', true)} *`"
                    v-model="form.full_name"
                    required
                    name="name"
                  />
                  <input
                    type="text"
                    :placeholder="`${$lang('contact_form.email', true)} *`"
                    v-model="form.email"
                    required
                    name="email"
                  />
                  <input
                    type="text"
                    :placeholder="`${$lang('contact_form.phone', true)} *`"
                    v-model="form.phone"
                    required
                    name="phone"
                  />
                  <Select
                    v-model="form.nationality"
                    :label="`${$lang('contact_form.nationality', true)} *`"
                    :options="nationalities"
                    name="$nationality"
                  />
                  <FormUploader
                    imageType
                    pdfType
                    name="$file"
                    v-model="form.file"
                    :tooBigErrorMsg="$lang('contact_form.tooBigError', true)"
                  >
                    <button type="button" class="btn btn-primary mb-3">
                      {{ $lang("contact_form.attach_cv", true) }}
                    </button>
                  </FormUploader>

                  <div class="ml-4 mb-3">
                    <b>{{ $lang("contact_form.campus", true) }}</b>
                    <Radio
                      label="Barcelona"
                      inputValue="Barcelona"
                      name="$campus"
                      v-model="form.campus"
                      class="checkbox-primary"
                    />
                    <Radio
                      label="Madrid"
                      inputValue="Madrid"
                      name="$campus"
                      v-model="form.campus"
                      class="checkbox-primary"
                    />
                  </div>

                  <textarea
                    :placeholder="$lang('contact_form.message', true)"
                    rows="4"
                    v-model="form.message"
                    name="message"
                  />

                  <Checkbox
                    class="ml-4 checkbox-primary"
                    :label="$lang('contact_form.terms', true)"
                    v-model="form.terms"
                    name="terms-accepted"
                    :isInvalid="terms_invalid"
                  />
                  <input type="text" name="honeypot" style="display:none">
                  <input type="hidden" name="subject" value="Work with us from - nlcollege.es" />
                  <input type="hidden" name="accessKey" value="61bf7109-e9cd-41a4-9d73-93f0e2c5ecf6">
                  <input type="hidden" name="redirectTo" value="https://nlcollege.es/accessprogram/thanks">
                  <input type="hidden" name="replyTo" value="@">
                  <button
                    type="submit"
                    class="btn btn-primary mt-5 ml-auto px-6"
                  >
                    {{ $lang("contact_form.button", true) }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import IconCard from "@/components/IconCard.vue";
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import FormUploader from "@/components/Forms/FormUploader.vue";
import Checkbox from "@/components/Forms/Checkbox.vue";
import Radio from "@/components/Forms/Radio.vue";
import Select from "@/components/Forms/Select.vue";
import axios from "axios";

export default {
  components: {
    PageHeader,
    IconCard,
    FormUploader,
    Checkbox,
    Radio,
    Select,
  },
  data: () => ({
    countries_en,
    countries_es,
    form: {
      full_name: null,
      email: null,
      phone: null,
      nationality: null,
      file: null,
      campus: "Barcelona",
      message: null,
      terms: false,
    },
    tried: false,
    terms_invalid: false,
  }),
  computed: {
    countries() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.name,
        label: x.name,
      }));
    },
    nationalities() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.isValid()) return;

      const fd = new FormData();
      fd.append("full_name", this.form.full_name);
      fd.append("email", this.form.email);
      fd.append("phone", this.form.phone);
      fd.append("nationality", this.form.nationality);
      fd.append("file", this.form.file);
      fd.append("campus", this.form.campus);
      fd.append("message", this.form.message);
      fd.append("terms_accepted", this.form.terms ? "YES" : "NO");

      axios.post(
        "https://getform.io/f/b2489d12-1fc8-4b01-9874-0ae178cf86e3",
        fd
      );

      this.clearForm();

      this.formSuccessMessage();
    },
    isValid() {
      if (!this.form.terms) {
        this.$toast.success(this.$lang("contact_form.check_terms_error", true));
        this.terms_invalid = true;
        return false;
      }

      this.terms_invalid = false;
      return true;
    },
    clearForm() {
      this.tried = false;
      this.terms_invalid = false;
      this.form = {
        full_name: null,
        email: null,
        phone: null,
        country: null,
        nationality: null,
        campus: "Barcelona",
        message: null,
        terms: true,
      };
    },
    formSuccessMessage() {
      this.$toast.success(this.$lang("contact_form.success_msg", true));
    },
  },
};
</script>

<style></style>
