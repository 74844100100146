<template>
  <div class="card" :class="`card-${theme} ${to ? 'card-button' : ''}`" @click="goTo">
    <div class="card-body d-flex gap-2">
      <div>
        <slot />
      </div>
      <ImageContainer :fileName="`icon-${icon}`" contain size="80" style="min-width: 80px" />
    </div>
  </div>
</template>

<script>
import ImageContainer from "@/components/ImageContainer.vue";

export default {
  components: {
    ImageContainer,
  },
  props: {
    theme: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "building",
    },
    to: {
      type: [Object, String],
      default: null,
    },
  },
  methods: {
    goTo() {
      if (!this.to) return;

      if (typeof this.to === "string" && this.to.slice(0, 4) === "http") {
        window.open(this.to);
      } else if (typeof this.to === 'function') {
        this.to();
      } else {
        this.$router.push(this.to);
      }
    },
  },
};
</script>

<style></style>
